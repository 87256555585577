<template lang="pug">
.rating(:class="{'rating_containerized': containerized}")
  .rating__container
    .rating__left
      h2.rating__title {{ $t('pages.ssr_landing.rating.title') }}
      p.rating__description.lp-colored-links(
        v-html="description"
      )
    ReviewIoBadge.rating__review-io(
      short
    )
</template>

<script>
import ReviewIoBadge from '@/components/shared/content/review-io-badge/index'

import { config } from '@/config'

export default {
  components: {
    ReviewIoBadge
  },

  props: {
    containerized: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    description () {
      const link = `${config.staticUrl}${this.$locatedLink('/community/')}`
      return this.$t('pages.ssr_landing.rating.description').replace('#{link}', link)
    }
  }
}
</script>

<style lang="scss" scoped>
.rating {
  z-index: 4;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding-top: 96px;
  position: relative;
  background: #26163B;
  margin-bottom: 96px;
  padding-bottom: 96px;

  &_containerized {
    padding-top: 0;
    padding-bottom: 0;
    background: transparent;

    &:last-child {
      margin-bottom: 150px;
    }

    .rating__container {
      width: 1152px;
      margin: 0 auto;
      max-width: 100%;
      padding: 96px 64px;
      position: relative;
      border-radius: 8px;
      background: #26163B;

      @include display-less(tablet) {
        padding: $gap * 2 $gap;
      }
    }
  }

  @include display(max) {
    margin-top: -5px;
  }

  @include display-less(tablet) {
    margin-top: -5vw;
    padding-top: 40px;
    margin-bottom: 40px;
    padding-bottom: 40px;
  }

  &__container {
    display: flex;
    margin: 0 auto;
    max-width: 100%;
    padding-left: $gap;
    padding-right: $gap;
    align-items: center;
    width: 1024px + $gap + $gap;
    justify-content: space-between;

    @include display-less(tablet) {
      margin-top: -5vw;
      padding-top: 40px;
      flex-direction: column;
    }
  }

  &__left {
    max-width: 640px;
    margin-right: $gap * 2;

    @include display-less(tablet) {
      margin-right: 0;
    }
  }

  &__title {
    color: #FFFFFF;
    font-size: 24px;
    line-height: 150%;
    font-weight: bold;
    margin-bottom: $gap * 0.5;
  }

  &__description {
    margin: 0;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    line-height: 160%;
  }

  &__review-io {
    width: 240px!important;

    @include display-less(tablet) {
      width: 100%;
      margin-top: $gap * 1.5;
    }
  }
}
</style>
