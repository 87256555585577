<template lang="pug">
  section.switch-section
    .switch-section__container
      BackgroundSvg.switch-section__bg
      .switch-section__content
        h4.switch-section__title {{ $t('pages.ssr_landing.switch_to_rss.title') }}
        i18n.ready__content-text-description(
          tag="p"
          path='pages.ssr_landing.switch_to_rss.text'
          class="switch-section__text"
        )
          template(v-slot:sixMonthsFree)
            strong {{ $t('pages.ssr_landing.switch_to_rss.sixMonthsFree') }}
          template(v-slot:unlimitedFeaturesLink)
              Link(:href="$locatedLink('/features/')") {{ $t('pages.ssr_landing.switch_to_rss.unlimitedFeaturesLink') }}

        RssUiButton.switch-section__link.lp-button.link-button-arrow-animation(
          rainbow
          data-test-id="buttonSwitchToRssCTA"
          :to="$locatedLink('/switch-to-rss/')"
        )
          template {{ $t('pages.ssr_landing.switch_to_rss.button') }}
          .link-button-arrow-animation__i
            SmallArrowIcon
        span.switch-section__info {{ $t('pages.ssr_landing.switch_to_rss.info') }}
</template>

<script>
import BackgroundSvg from './svg/Background'
import SmallArrowIcon from '@/components/icons/default/ArrowSmall'
import Link from '@/components/common/Link/Link'

export default {
  components: {
    BackgroundSvg,
    SmallArrowIcon,
    Link
  }
}
</script>

<style lang="scss" scoped>
.switch-section {
  width: 100%;
  margin-top: 140px;
  margin-bottom: $gap * 6.5;

  @include display-less(tablet) {
    margin-top: 0;
    margin-bottom: 40px;
    padding: 0 $gap;
  }

  &__container {
    width: 1152px;
    margin: 0 auto;
    display: block;
    max-width: 100%;
    overflow: hidden;
    position: relative;
    border-radius: 8px;
    background: #25163A;
  }

  &__content {
    z-index: 2;
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: column;
    padding: $gap * 4 259px;

    @include display-less(desktop) {
      padding-left: $gap * 2;
      padding-right: $gap * 2;
    }

    @include display-less(tablet) {
      padding: 40px $gap * 1.5;
      padding-bottom: 69px;
    }
  }

  &__title {
    color: #fff;
    font-size: 24px;
    line-height: 150%;
    font-weight: bold;
    text-align: center;
    margin-bottom: $gap * 0.5;

    @include display-less(tablet) {
      font-size: 22px;
    }
  }

  &__text {
    color: #fff;
    margin-top: 0;
    font-size: 18px;
    font-weight: 500;
    line-height: 160%;
    text-align: center;
    margin-bottom: $gap * 3;

    ::v-deep strong {
      color: #fff;
    }

    @include display-less(tablet) {
      font-size: 16px;
    }
  }

  &__info {
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    line-height: 160%;
    margin-top: $gap * 0.5;
  }

  &__bg {
    z-index: 1;
    right: -8px;
    bottom: $gap * 2;
    position: absolute;

    @include display-less(tablet) {
      left: 50%;
      bottom: 42px;
      margin-left: -260px;
    }
  }

  &__link {
    @include display-less(phablet) {
      width: 100%;
    }
  }
}
</style>
