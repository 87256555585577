<template>
  <svg width="653" height="488" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="m442.903 447.488-77.911-67.605a8.1 8.1 0 0 0-11.506 1.364c-2.803 3.686-2.219 8.936 1.325 11.908l81.639 62.788 6.453-8.455Z" fill="url(#paint0_linear_356_2112)" />
    <path d="m429.038 439.827-67.15-56.512a4.212 4.212 0 0 0-6.035.7 4.474 4.474 0 0 0 .688 6.193l69.087 54.003 3.41-4.384Z" fill="url(#paint1_linear_356_2112)" />
    <path d="m560.057 366.418-103.873-88.707c-4.751-3.818-11.684-3.053-15.492 1.71-3.802 4.774-3.033 11.737 1.719 15.56l108.872 82.383 8.774-10.946Z" fill="url(#paint2_linear_356_2112)" />
    <path d="m541.022 356.151-89.647-74.221a5.767 5.767 0 0 0-8.101.898 5.764 5.764 0 0 0 .897 8.099l92.273 70.948 4.578-5.724Z" fill="url(#paint3_linear_356_2112)" fill-opacity=".5" />
    <path opacity=".25" d="M41.062 370.265c22.677 0 41.061 18.384 41.061 41.061 0 22.678-18.384 41.062-41.062 41.062C18.385 452.388 0 434.004 0 411.326c0-22.677 18.384-41.061 41.062-41.061Z" fill="url(#paint4_linear_356_2112)" />
    <path d="M640.948 36.239a49.59 49.59 0 0 0-89.537 19.496 49.592 49.592 0 1 0 89.537-19.496Z" fill="url(#paint5_linear_356_2112)" />
    <path opacity=".32" d="M591.272 383.311a30.924 30.924 0 0 0 56.151 10.652 30.93 30.93 0 0 0-.325-34.373 30.924 30.924 0 0 0-31.884-12.842c-16.703 3.491-27.418 19.856-23.942 36.563Z" fill="url(#paint6_linear_356_2112)" />
    <path d="M274.124 278.898c6.627 0 11.999 5.372 11.999 11.999 0 6.626-5.372 11.998-11.999 11.998-6.626 0-11.998-5.372-11.998-11.998 0-6.627 5.372-11.999 11.998-11.999Z" fill="url(#paint7_linear_356_2112)" />
    <path d="m345.849 191.112-43.845-35.528a4.585 4.585 0 0 0-6.408.851 4.54 4.54 0 0 0 .854 6.384l45.865 32.89 3.534-4.597Z" fill="url(#paint8_linear_356_2112)" />
    <path d="m330.384 182.117-1.347 1.327c-.673 0-31.871-21.749-31.871-21.749a2.889 2.889 0 0 1-1.444-2.503 2.92 2.92 0 0 1 1.704-2.668 3.003 3.003 0 0 1 3.173.411l29.785 25.182Z" fill="url(#paint9_linear_356_2112)" />
    <path opacity=".31" d="m394.376 351.036-33.793-27.676a3.507 3.507 0 0 0-4.94.654 3.564 3.564 0 0 0 .649 4.974l35.348 25.634 2.736-3.586Z" fill="url(#paint10_linear_356_2112)" />
    <path d="M358.113 324.759a1.6 1.6 0 1 1 0 3.2 1.6 1.6 0 0 1 0-3.2Z" fill="#D9D0ED" />
    <path d="m549.556 473.118-70.79-57.264c-3.239-2.455-7.866-1.844-10.347 1.366a7.303 7.303 0 0 0 1.375 10.28l74.058 52.973 5.704-7.355Z" fill="url(#paint11_linear_356_2112)" />
    <path d="m636.479 483.706-35.171-28.61a3.667 3.667 0 0 0-5.859 3.396c.13.963.636 1.834 1.408 2.423l36.783 26.491 2.839-3.7Z" fill="url(#paint12_linear_356_2112)" />
    <path d="M147.672 104.787a25.33 25.33 0 1 0 25.319-25.33c-13.985.006-25.319 11.345-25.319 25.33Z" fill="url(#paint13_linear_356_2112)" />
    <path opacity=".24" d="M645.011 389.029a8.266 8.266 0 1 0-16.532-.02 8.266 8.266 0 0 0 16.532.02Z" fill="url(#paint14_linear_356_2112)" />
    <path opacity=".24" d="M629.546 401.027a3.732 3.732 0 1 0-3.723 3.722 3.722 3.722 0 0 0 3.723-3.722Z" fill="url(#paint15_linear_356_2112)" />
    <path opacity=".31" d="m459.434 180.39-33.792-27.675a3.508 3.508 0 0 0-4.941.653 3.565 3.565 0 0 0 .65 4.975l35.348 25.634 2.735-3.587Z" fill="url(#paint16_linear_356_2112)" />
    <path d="M422.639 153.581a1.6 1.6 0 1 1 0 3.2 1.6 1.6 0 0 1 0-3.2Z" fill="#D9D0ED" />
    <path opacity=".31" d="m267.459 475.83-33.793-27.685a3.506 3.506 0 0 0-4.94.653 3.568 3.568 0 0 0 .649 4.977l35.402 25.631 2.682-3.576Z" fill="url(#paint17_linear_356_2112)" />
    <path d="M231.196 449.01a1.6 1.6 0 1 1 0 3.2 1.6 1.6 0 0 1 0-3.2Z" fill="#D9D0ED" />
    <path opacity=".32" d="M352.472 272.403c6.868 8.269 19.138 9.405 27.408 2.539 8.27-6.867 9.407-19.138 2.541-27.408-6.867-8.27-19.137-9.408-27.408-2.543a19.464 19.464 0 0 0-2.541 27.412Z" fill="url(#paint18_linear_356_2112)" />
    <path d="M394.375 75.19a2.133 2.133 0 1 1 0 4.267 2.133 2.133 0 0 1 0-4.266ZM299.188 157.847a1.866 1.866 0 1 1 0 3.732 1.866 1.866 0 0 1 0-3.732ZM229.597 267.7a2.133 2.133 0 1 1 0 4.266 2.133 2.133 0 0 1 0-4.266ZM617.281 427.68a1.6 1.6 0 1 1 0 3.2 1.6 1.6 0 0 1 0-3.2ZM429.037 233.571a1.6 1.6 0 1 1 .001 3.199 1.6 1.6 0 0 1-.001-3.199ZM81.013 66.659a1.6 1.6 0 1 1 0 3.2 1.6 1.6 0 0 1 0-3.2ZM443.436 469.275a1.6 1.6 0 1 1 0 3.2 1.6 1.6 0 0 1 0-3.2ZM648.21 142.382a1.6 1.6 0 1 1 0 3.2 1.6 1.6 0 0 1 0-3.2ZM391.709 0a1.6 1.6 0 1 1 0 3.2 1.6 1.6 0 0 1 0-3.2Z" fill="#D9D0ED" />
    <defs>
      <linearGradient
        id="paint0_linear_356_2112"
        x1="349.972"
        y1="387.799"
        x2="430.861"
        y2="372.684"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#300D5D" />
        <stop offset="1" stop-color="#2D0D53" stop-opacity=".01" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_356_2112"
        x1="336.009"
        y1="252.079"
        x2="464.567"
        y2="392.604"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F8B700" />
        <stop offset=".33" stop-color="#F87900" />
        <stop offset=".67" stop-color="#AE35DD" />
        <stop offset="1" stop-color="#AE35DD" stop-opacity=".01" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_356_2112"
        x1="436.752"
        y1="285.125"
        x2="545.763"
        y2="267.856"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#300D5D" />
        <stop offset="1" stop-color="#2D0D53" stop-opacity=".01" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_356_2112"
        x1="483.392"
        y1="240.983"
        x2="562.701"
        y2="323.651"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F8B700" stop-opacity=".8" />
        <stop offset=".33" stop-color="#F87900" stop-opacity=".6" />
        <stop offset=".67" stop-color="#AE35DD" stop-opacity=".3" />
        <stop offset="1" stop-color="#AE35DD" stop-opacity=".01" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_356_2112"
        x1="-38.873"
        y1="396.404"
        x2="56.93"
        y2="324.881"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#CB1B5E" />
        <stop offset=".58" stop-color="#300D5D" />
        <stop offset="1" stop-color="#2D0D53" stop-opacity=".01" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_356_2112"
        x1="211.144"
        y1="229.754"
        x2="462.358"
        y2="-86.776"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#AE35DD" />
        <stop offset=".96" stop-color="#FE4F45" stop-opacity=".01" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_356_2112"
        x1="580.541"
        y1="356.843"
        x2="641.165"
        y2="344.47"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset=".05" stop-color="#420C60" stop-opacity=".9" />
        <stop offset=".39" stop-color="#410C60" stop-opacity=".88" />
        <stop offset=".57" stop-color="#400C5E" stop-opacity=".79" />
        <stop offset=".71" stop-color="#3C0C5C" stop-opacity=".65" />
        <stop offset=".83" stop-color="#380C5A" stop-opacity=".46" />
        <stop offset=".93" stop-color="#320D56" stop-opacity=".2" />
        <stop offset="1" stop-color="#2D0D53" stop-opacity=".01" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_356_2112"
        x1="206.504"
        y1="270.098"
        x2="280.199"
        y2="249.464"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset=".01" stop-color="#F87900" stop-opacity=".5" />
        <stop offset=".53" stop-color="#AE35DD" stop-opacity=".3" />
        <stop offset=".91" stop-color="#AE35DD" stop-opacity=".01" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_356_2112"
        x1="323.885"
        y1="152.421"
        x2="352.933"
        y2="186.359"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#300D5D" />
        <stop offset="1" stop-color="#2D0D53" stop-opacity=".01" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_356_2112"
        x1="310.284"
        y1="136.58"
        x2="343.441"
        y2="175.538"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#B7BED0" stop-opacity=".7" />
        <stop offset=".13" stop-color="#9795B3" stop-opacity=".54" />
        <stop offset=".32" stop-color="#716591" stop-opacity=".35" />
        <stop offset=".5" stop-color="#543E76" stop-opacity=".2" />
        <stop offset=".68" stop-color="#3E2363" stop-opacity=".09" />
        <stop offset=".85" stop-color="#311357" stop-opacity=".02" />
        <stop offset="1" stop-color="#2D0D53" stop-opacity=".01" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_356_2112"
        x1="354.919"
        y1="322.639"
        x2="394.376"
        y2="322.639"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#B7BED0" />
        <stop offset=".13" stop-color="#9896B4" stop-opacity=".78" />
        <stop offset=".29" stop-color="#786D97" stop-opacity=".54" />
        <stop offset=".44" stop-color="#5D4A7E" stop-opacity=".35" />
        <stop offset=".59" stop-color="#48306B" stop-opacity=".2" />
        <stop offset=".74" stop-color="#391C5E" stop-opacity=".09" />
        <stop offset=".88" stop-color="#301156" stop-opacity=".02" />
        <stop offset="1" stop-color="#2D0D53" stop-opacity=".01" />
      </linearGradient>
      <linearGradient
        id="paint11_linear_356_2112"
        x1="520.476"
        y1="413.877"
        x2="559.929"
        y2="468.621"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#370F6A" />
        <stop offset="1" stop-color="#2D0D53" stop-opacity=".01" />
      </linearGradient>
      <linearGradient
        id="paint12_linear_356_2112"
        x1="629.079"
        y1="431.428"
        x2="682.777"
        y2="497.433"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#300D5D" />
        <stop offset="1" stop-color="#2D0D53" stop-opacity=".01" />
      </linearGradient>
      <linearGradient
        id="paint13_linear_356_2112"
        x1="97.395"
        y1="113.548"
        x2="164.628"
        y2="33.972"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FF543C" stop-opacity=".3" />
        <stop offset=".22" stop-color="#DE4742" stop-opacity=".32" />
        <stop offset=".58" stop-color="#842553" stop-opacity=".36" />
        <stop offset=".8" stop-color="#420C60" stop-opacity=".4" />
        <stop offset="1" stop-color="#2D0D53" stop-opacity=".01" />
      </linearGradient>
      <linearGradient
        id="paint14_linear_356_2112"
        x1="620.091"
        y1="389.65"
        x2="636.043"
        y2="370.772"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#300D5D" />
        <stop offset="1" stop-color="#2D0D53" stop-opacity=".01" />
      </linearGradient>
      <linearGradient
        id="paint15_linear_356_2112"
        x1="618.28"
        y1="401.303"
        x2="625.485"
        y2="392.784"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#300D5D" />
        <stop offset="1" stop-color="#2D0D53" stop-opacity=".01" />
      </linearGradient>
      <linearGradient
        id="paint16_linear_356_2112"
        x1="419.977"
        y1="151.982"
        x2="459.434"
        y2="151.982"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#B7BED0" />
        <stop offset=".13" stop-color="#9896B4" stop-opacity=".78" />
        <stop offset=".29" stop-color="#786D97" stop-opacity=".54" />
        <stop offset=".44" stop-color="#5D4A7E" stop-opacity=".35" />
        <stop offset=".59" stop-color="#48306B" stop-opacity=".2" />
        <stop offset=".74" stop-color="#391C5E" stop-opacity=".09" />
        <stop offset=".88" stop-color="#301156" stop-opacity=".02" />
        <stop offset="1" stop-color="#2D0D53" stop-opacity=".01" />
      </linearGradient>
      <linearGradient
        id="paint17_linear_356_2112"
        x1="228.002"
        y1="447.417"
        x2="267.459"
        y2="447.417"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#B7BED0" />
        <stop offset=".13" stop-color="#9896B4" stop-opacity=".78" />
        <stop offset=".29" stop-color="#786D97" stop-opacity=".54" />
        <stop offset=".44" stop-color="#5D4A7E" stop-opacity=".35" />
        <stop offset=".59" stop-color="#48306B" stop-opacity=".2" />
        <stop offset=".74" stop-color="#391C5E" stop-opacity=".09" />
        <stop offset=".88" stop-color="#301156" stop-opacity=".02" />
        <stop offset="1" stop-color="#2D0D53" stop-opacity=".01" />
      </linearGradient>
      <linearGradient
        id="paint18_linear_356_2112"
        x1="342.765"
        y1="255.207"
        x2="372.708"
        y2="230.331"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#420C60" stop-opacity=".9" />
        <stop offset=".36" stop-color="#410C60" stop-opacity=".88" />
        <stop offset=".54" stop-color="#400C5E" stop-opacity=".79" />
        <stop offset=".69" stop-color="#3C0C5C" stop-opacity=".65" />
        <stop offset=".82" stop-color="#380C5A" stop-opacity=".46" />
        <stop offset=".93" stop-color="#320D56" stop-opacity=".2" />
        <stop offset="1" stop-color="#2D0D53" stop-opacity=".01" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {

}
</script>
